"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
//axios.defaults.headers.common['Authorization'] = '';
//axios.defaults.headers.common['parceiro'] = 'BPP';

let config = {
    //prod
    baseURL: process.env.baseURL || process.env.apiUrl || "https://core.bpp.com.br/backofficeshim"
    //Homolog
    // baseURL: process.env.baseURL || process.env.apiUrl || "https://core.hom-bpp.com.br/backofficeshim"
    //local port SSL
    //baseURL: process.env.baseURL || process.env.apiUrl || "http://localhost:7712"
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    (config) => {
        config.headers.common['Authorization'] = sessionStorage.token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    (response) => {
        if (response.config.url.includes('auth/token')) {
            let responseJson = JSON.parse(response.data.json);
            sessionStorage.setItem('token', responseJson.token);
            sessionStorage.setItem('name', responseJson.name);
            sessionStorage.setItem('pessoaId', responseJson.pessoaId);
            
        }
        return response;
    },
    (error) => {
        if (error.toString().includes('401') && (document.location.pathname !== '/')) {
            sessionStorage.removeItem('token');
            document.location.reload(true);
        }  
        return Promise.reject(error);
    }
);


Plugin.install = function(Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;